/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import { MdVerified } from 'react-icons/md'
import { AiFillEye } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { GiShieldDisabled } from 'react-icons/gi'
import { ExpenseCardContainer } from './styles'
import { expenseTypeIcon } from '../../utils/expensnes/ExpensesIconsHandler'
import { expensesDateFormatter } from '../../utils/expensnes/dateFormatter'
import TravelerTag from '../TravelerTag'
import { priceFormatter } from '../../utils/expensnes/priceFormatter'
import { Button } from '../Button'
import { useExpenses } from '../../hooks/use-expenses'
import { AdvanceMoney } from '../../interfaces/advanced-money'
import ConfirmPaymentModal from '../../modals/ConfirmPaymentModal'
import { advanceMoneyOutOfPolicyHandler } from '../../utils/expensnes/expenseOutOfPolicyHandler'

interface Props {
    advancedMoney: AdvanceMoney
}

export default function AdvancedMoneyCard({ advancedMoney }: Props) {
    const [isLoading, setLoading] = useState<boolean>(false)
    const {
        openAdvancedMoneyModalHandler,
        completeAdvanceMoney,
        payAdvanceMoney,
    } = useExpenses()
    const [openConfirmationModal, setConfirmationModal] = useState(false)

    async function conciliateExpensesHandler(date?: Date) {
        setLoading(true)
        const response = date
            ? await payAdvanceMoney(advancedMoney.id, date)
            : await payAdvanceMoney(advancedMoney.id, undefined)
        if (response) {
            toast.success('Conciliação realizada com sucesso!')
        }
        setLoading(false)
    }

    const ButtonBasedOnType = () => {
        if (advancedMoney.status === 'CONFIRMED') {
            return (
                <Button
                    type="button"
                    busy={isLoading}
                    buttonType="FILLED"
                    icon={MdVerified}
                    color="PRIMARY"
                    text="Efetuar pagamento"
                    onClick={() => setConfirmationModal(true)}
                />
            )
        }
        if (advancedMoney.status === 'PAID') {
            return (
                <Button
                    type="button"
                    busy={isLoading}
                    buttonType="FILLED"
                    icon={MdVerified}
                    color="PRIMARY"
                    text="Confirmar prestação de conta"
                    onClick={async () => {
                        setLoading(true)

                        const response = await completeAdvanceMoney(
                            advancedMoney.id
                        )
                        if (response) {
                            toast.success(
                                'Prestação de conta realizada com sucesso!'
                            )
                            setLoading(false)
                        }
                        setLoading(false)
                    }}
                />
            )
        }
        if (advancedMoney.status === 'COMPLETED') {
            return 'Pagamento realizado'
        }
        return null
    }

    return (
        <ExpenseCardContainer>
            <div className="description">
                <div className="image">
                    <img
                        src={expenseTypeIcon('ADVANCED_MONEY')}
                        alt="expense-image"
                    />
                </div>
                <div className="observations">
                    <h3 className="f16-500-dark">
                        {`Adiantamento ${advancedMoney.numberIdentifier || ''}`}
                    </h3>
                    {/* <h3 className="f16-500-dark">Adiantamento</h3> */}
                    <h3 className="f14-500-gray">
                        {expensesDateFormatter(
                            new Date(advancedMoney.createdAt).toDateString()
                        )}
                    </h3>
                </div>
            </div>
            <div className="traveler">
                <TravelerTag
                    photo={
                        advancedMoney.traveler.photoUrl
                            ? { url: advancedMoney.traveler.photoUrl }
                            : undefined
                    }
                    firstName={advancedMoney.traveler.firstName || ''}
                    lastName={advancedMoney.traveler.lastName || ''}
                />
            </div>
            <div className="out-of-policy">
                {advanceMoneyOutOfPolicyHandler(
                    advancedMoney.travelerClearanceEvaluation
                ).isOutOfPolicy ? (
                    <GiShieldDisabled />
                ) : (
                    <div />
                )}
            </div>
            <div className="price">
                <p className="f18-700-dark">{`R$${priceFormatter(
                    advancedMoney.amount || 0
                )}`}</p>
            </div>
            <div className="action-btns">
                {ButtonBasedOnType()}
                <button
                    onClick={() => openAdvancedMoneyModalHandler(advancedMoney)}
                    className="see-more-btn"
                    type="button"
                >
                    <AiFillEye />
                </button>
            </div>
            {openConfirmationModal && (
                <ConfirmPaymentModal
                    isOpen={openConfirmationModal}
                    callback={(date?: Date) => conciliateExpensesHandler(date)}
                    onRequestClose={() => setConfirmationModal(false)}
                />
            )}
        </ExpenseCardContainer>
    )
}
